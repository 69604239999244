<template>
  <footer class="footer-main font-white background-black">
    <FooterNewsletter />

    <FooterMenu />

    <FooterBottom />
  </footer>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
