<template>
  <!-- Form -->
  <div class="newsletter-form" :class="{ '--loading': loading }">
    <FormKit type="form" ref="formEl" :actions="false" @submit="onSubmit">
      <div class="form-row form-row-main">
        <!-- Email -->
        <FormKit
          type="email"
          name="email"
          :label="$t('footer.newsletter.emailField')"
          validation="required|email"
          :placeholder="$t('footer.newsletter.emailPlaceholder')"
        />
        <!-- Submit button -->
        <button
          type="submit"
          class="button-round-1 is-transparent-negative show-for-large"
        >
          <span>{{ $t('footer.newsletter.cta') }}</span>
        </button>
        <button type="submit" class="hide-for-large">
          <img
            class="w-100"
            src="@/assets/img/ico/freccia-arancio.svg"
            width="54"
            height="53"
            :alt="$t('footer.newsletter.cta')"
          />
        </button>
      </div>

      <div class="form-row form-row-gdpr">
        <!-- GDPR Check (desktop) -->
        <div>
          <FormKit
            type="checkbox"
            :label="$t('footer.newsletter.gdpr')"
            name="gdpr"
            validation="accepted"
            :validation-messages="{
              accepted: $t('footer.newsletter.gdprError'),
            }"
          >
            <template #label>
              <div class="gdpr-checkbox-text">
                <!-- TODO: Capire  come mai non parsa le quotes che vengono dal $t -->
                <p>
                  Pursuant to and in accordance with EU Reg. 2016/679 - GDPR I
                  give my consent to the processing of my personal data as per
                  the
                  <a target="“_blank”" href="/privacy-policy">privacy notice</a>
                </p>
              </div>
            </template>
          </FormKit>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script>
export default {
  name: 'NewsletterForm',
}
</script>

<script setup>
// import { load } from 'recaptcha-v3'
import { reset } from '@formkit/core'

const emit = defineEmits(['newsletter:success', 'newsletter:failure'])
const { t } = useI18n()

const formEl = ref(null)
const loading = ref(false)

const onSubmitClick = () => {
  // retrieve the core node (several ways to do this):
  const node = formEl.value.node
  // submit the form!
  node.submit()
}

const onSubmit = (formkitData, node) => {
  node.clearErrors()

  loading.value = true

  // Form data
  const formData = {}
  formData['Email'] = formkitData.email
  formData['Gdpr'] = formkitData.gdpr
  console.log(JSON.stringify(formData))

  // Mailup data
  const mailupClientId = 'a6e8655d-4c2a-4161-99a2-48aba10caed8'
  const mailupClientSecret = 'f30ea7f4-b479-4bf7-9a59-bcbe8d2015ac'
  const mailupUsername = 'm73905'
  const mailupPsw = 'Sunteam2024'
  const mailupListId = '5'
  const BASE64_ENCODED_CLIENTCREDENTIALS = btoa(
    `${mailupClientId}:${mailupClientSecret}`
  )

  // Step1: Get access token (passiamo da Nodered per proxare le chiamate con usr e psw di Mailup in chiaro..)
  /* fetch(`https://services.mailup.com/Authorization/OAuth/Token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${BASE64_ENCODED_CLIENTCREDENTIALS}`,
    },
    body: `grant_type=password&username=${mailupUsername}&password=${mailupPsw}`,
  }) */
  fetch(`https://nodered.quammbase.it/suntimes/mailup`)
    .then((response) => response.json())
    .then((res) => {
      if (res && res.access_token) {
        const accessToken = res.access_token

        // Step2: Call to subscribe email
        fetch(
          `https://services.mailup.com/API/v1.1/Rest/ConsoleService.svc/Console/List/${mailupListId}/Recipient?ConfirmEmail=true`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              // 'Cache-control': 'no-cache'
            },
            body: JSON.stringify(formData),
          }
        )
          .then(function (response) {
            loading.value = false

            if (response) {
              console.log(response)
              emit('newsletter:success')
              reset(node)
              console.log('SUBMIT!!!')
            } else {
              emit('newsletter:failure')
              console.log(response)
            }
          })
          .catch(function (error) {
            console.log(error)
            loading.value = false
            emit('newsletter:failure')
          })
      } else {
        loading.value = false
        emit('newsletter:failure')
        console.log(res)
      }
    })
    .catch(function (error) {
      console.log(error)
      loading.value = false
      emit('newsletter:failure')
    })
}
</script>

<style lang="scss">
@import './style.scss';
</style>
