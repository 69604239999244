<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <VitePwaManifest />
      <NuxtPage />
      <div class="preloader-curtain" data-animate="preloader-curtain"></div>
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index.js'

const { locale } = useI18n()

// TODO: Evitare il watch e quando rispondono implementare https://github.com/nuxt-modules/apollo/discussions/529
await useAsyncData(() => useStore().fetchDatoConfiguration())

watch(
  () => locale.value,
  async () => {
    await useAsyncData(() => useStore().fetchDatoConfiguration())
  }
)

const { lenis } = useLenis()
provide('lenis', lenis)
</script>
