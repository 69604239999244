import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images.js'

export const getHomepage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query getHomepage($locale: SiteLocale) {
    homepage(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      preBodyText
      body {
        __typename
        ... on FullscreenVideoRecord {
          id
          overlayFullVideo
          previewVideo
          previewVideoMobile
          showMuteUnmute
        }
        ... on CursorMaskRecord {
          id
          text
          overlapText
          isDark
        }
        ... on ClipPathTextRecord {
          id
          title
          data {
            id
            title
            descriptionHtml
            speed
          }
        }
        ... on FullscreenCarouselRecord {
          id
          slides {
            id
            pretitle
            title
            image {
              landscape: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1920
                  h: 1080
                  ar: "16/9"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1536
                  h: 2048
                  ar: "9/16"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
            }
            linkUrl
            linkText
            openToNewTab
          }
        }
        ... on HomeProjectsBlockRecord {
          id
          highlightProject {
            id
            name
            slug
            tags {
              id
              name
              slug
            }
            hero {
              title
            }
            presentationImage {
              landscape: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1920
                  h: 1080
                  ar: "16/9"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1536
                  h: 2048
                  ar: "9/16"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
          homeHighlightCaseVideo
          homeHighlightCaseVideoMobile
          homeHighlightCaseImage {
            landscape: responsiveImage(
              imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            portrait: responsiveImage(
              imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
          projectsCarousel {
            id
            name
            slug
            hero {
              id
              pretitle
              title
            }
            tags {
              id
              name
              slug
            }
            presentationVideo
            presentationImage {
              landscape: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 472
                  h: 587
                  ar: "472/587"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 338
                  h: 421
                  ar: "338/421"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on TextBlockRecord {
          id
          title
          description
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
        }
      }
    }
  }
`
