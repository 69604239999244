import gql from 'graphql-tag'

export const datoState = gql`
  query datoState($locale: SiteLocale) {
    _site(locale: $locale) {
      globalSeo {
        siteName
        fallbackSeo {
          description
          title
          image {
            url(imgixParams: { w: "1200", h: "630" })
          }
          twitterCard
        }
        titleSuffix
      }
    }
  }
`
