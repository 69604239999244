<template>
  <nav
    ref="header"
    class="nav-menu"
    :class="{
      '--open': openOverlay,
      '--scrolling': isScrolling,
      '--light': isLight && !isScrolling,
      '--hide-links': isSpecialPage,
    }"
  >
    <div class="row-40-16">
      <NavigationLarge
        class="nav-menu__large show-for-large"
        :is-light="isLight && !isScrolling"
        :external="external"
      />
      <NavigationSmall
        class="nav-menu__small hide-for-large"
        @open-menu="toggleOverlay"
        :is-light="isLight && !isScrolling"
        :external="external"
        :overlay-open="openOverlay"
      />
    </div>
    <!-- Overlay -->
    <WidgetOverlayFullScreen
      class="nav-menu__overlay hide-for-large"
      ref="overlay"
      :right="true"
    >
      <div class="nav-menu__overlay__wrap" data-lenis-prevent>
        <NavigationMenuLinks
          class="nav-menu__links --main"
          ref="mainMenu"
          :links="linksMain"
          :external="external"
        />
      </div>
    </WidgetOverlayFullScreen>
  </nav>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { debounce } from '~/assets/js/utils.js'

import { navMenu } from '@/bucket/navigation.js'

const { locale } = useI18n()
const route = useRoute()

const lightTheme = setLightTheme()
const isLight = ref(false)

const overlay = ref(null)
const mainMenu = ref(null)
const openOverlay = ref(false)

// Hide / Show nav
const hideMenu = ref(false)
const lastScrollPosition = ref(0)
const header = ref(null)
const isTop = ref(true)

const tl = ref(null)

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
})

const linksMain = navMenu.linksMain

watch(
  () => lightTheme.value,
  (lightTheme) => {
    if (lightTheme) {
      isLight.value = true
    } else {
      isLight.value = false
    }
  }
)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}
watch(
  () => openOverlay.value,
  (openOverlay) => {
    tl.value = openOverlay
      ? animateOverlayIn().duration(1)
      : animateOverlayOut().duration(1.5)
    tl.value.play()
  }
)
watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
    setTimeout(() => {
      checkTheme()
    }, 500)
  }
)

const handleScroll = () => {
  const menuHeight = header.value.getBoundingClientRect().height
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop

  if (
    currentScrollPosition > lastScrollPosition.value &&
    currentScrollPosition > menuHeight
  ) {
    // Scrolling down
    hideMenu.value = true
    header.value.classList.add('--hide-nav')
    isTop.value = false
  } else {
    // Scrolling up
    if (currentScrollPosition <= menuHeight) {
      isTop.value = true
    }
    hideMenu.value = false
    header.value.classList.remove('--hide-nav')
  }
  lastScrollPosition.value =
    currentScrollPosition <= 0 ? 0 : currentScrollPosition
}

const checkTheme = () => {
  const isHomePage = ['index'].includes(route?.name?.split('___')[0])
  const isProjectPage = ['project'].includes(route?.name?.split('___')[0])
  const isStaticPage = [
    'cookie-policy',
    'privacy-policy',
    'whistleblowing',
  ].includes(route?.name?.split('___')[0])
  const isLightPage = isHomePage || isProjectPage || isStaticPage

  if (isLightPage) {
    if (document.body.classList?.contains('--light-theme')) {
      lightTheme.value = true
    } else {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
    }
  } else {
    document.body.classList?.remove('--light-theme')
    lightTheme.value = false
  }
}

const isScrolling = computed(() => !isTop.value && !hideMenu.value)
const isSpecialPage = computed(
  () => route.path && route.path === '/fight-gravity'
)

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 1.5,

    onStart: () => {
      document.body.setAttribute('data-menu-overlay-open', true)
    },
    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(overlay.value.animateIn().play(), '<')
  if (mainMenu.value?.animateIn) {
    tl.add(mainMenu.value.animateIn().play())
  }

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 3,
    onComplete: () => {
      document.body.removeAttribute('data-menu-overlay-open', false)

      tl.kill()
    },
  })

  tl.add(overlay.value.animateOut().play(), '<')

  if (mainMenu.value && mainMenu.value.animateOut) {
    tl.add(mainMenu.value.animateOut().play())
  }

  return tl
}

onMounted(() => {
  checkTheme()
  window.addEventListener('resize', debounce(onResize))
  window.addEventListener('scroll', handleScroll)
  onResize()
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  clearTimeout(closeTimeout)
})

const onResize = () => {
  openOverlay.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
