import gql from 'graphql-tag'
import { responsiveImageFragment } from '../fragments/images.js'

export const heroFragment = gql`
  ${responsiveImageFragment}
  fragment HeroFragment on HeroRecord {
    id
    pretitle
    title
    subtitle
    videoBackgroundUrl
    videoBackgroundMobile
    showMuteUnmute
    image {
      landscape: responsiveImage(
        imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
      ) {
        ...ResponsiveImageFragment
      }
      portrait: responsiveImage(
        imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
      ) {
        ...ResponsiveImageFragment
      }
    }
    lightMode
  }
`
