<template>
  <div
    @click="toggleMenu"
    :class="{ active: isActive, light: isLight }"
    class="hamburger"
  >
    <span class="hamburger__line hamburger__line1"></span>
    <span class="hamburger__line hamburger__line2"></span>
    <span class="hamburger__line hamburger__line3"></span>
    <span class="hamburger__line hamburger__line4"></span>
  </div>
</template>

<script>
export default {
  name: 'NavigationHamburger',
}
</script>

<script setup>
const emit = defineEmits(['toggle:menu'])
const props = defineProps({
  isActive: Boolean,
  isLight: Boolean,
})

const toggleMenu = () => {
  emit('toggle:menu')
}
</script>

<style lang="scss">
@import './style.scss';
</style>
