export const navMenu = {
  linksMain: [
    {
      key: 'who',
      route: { name: 'who' },
    },
    {
      key: 'what',
      route: { name: 'what' },
    },
    {
      key: 'why',
      route: { name: 'why' },
    },
    {
      key: 'getInTouch',
      route: { name: 'get-in-touch' },
    },
  ],
  linksBack: [
    {
      key: 'back',
      class: 'nav-menu__links__separator --small-link --top',
      back: true,
      action: 'main',
    },
  ],
}
