import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const parallaxElement = (el, binding) => {
  const targets = el.querySelectorAll(binding.value.target)
  gsap.utils.toArray(targets).forEach((target) => {
    const trigger = target.parentNode
    gsap.set(trigger, { overflow: 'hidden' })
    gsap.set(target, { display: 'block' })
    gsap.set(target, {
      // height: 'auto',
      yPercent: () => -binding.value.offset || -15,
      scale: 1.05,
    })

    gsap.to(target, {
      yPercent: () => binding.value.offset || 15,
      // opacity: 0.75,
      ease: 'none',
      scrollTrigger: {
        trigger: trigger,
        scrub: true, // smooth animation
        start: 'top bottom',
        end: 'bottom top',
      },
    })
  })
}
