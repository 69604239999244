import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'
import { heroFragment } from '../fragments/commons.js'
import { responsiveImageFragment } from '../fragments/images.js'

export const getWhoPage = gql`
  ${seoFragment}
  ${heroFragment}
  ${responsiveImageFragment}
  query getWhoPage($locale: SiteLocale) {
    whoPage(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      hero {
        ...HeroFragment
      }
      body {
        __typename
        ... on TimelineBlockRecord {
          id
          title
          timelineList {
            id
            year
            description
            topLeftImage {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            topLeftImageText
            rightImage {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            rightImageText
            bottomLeftImage {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on CrewBlockRecord {
          id
          pretitle
          title
          crewList {
            id
            members {
              id
              name
              job
              image {
                id
                custom: responsiveImage(
                  imgixParams: { fm: webp, q: "60", fit: crop }
                ) {
                  ...ResponsiveImageFragment
                }
              }
            }
          }
        }
        ... on TwinBlockRecord {
          id
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          items {
            id
            text
            image {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on ProjectsCarouselRecord {
          id
          title
          slides {
            id
            name
            slug
            hero {
              id
              pretitle
              title
            }
            tags {
              id
              name
              slug
            }
            presentationVideo
            presentationImage {
              landscape: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 472
                  h: 587
                  ar: "472/587"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 338
                  h: 421
                  ar: "338/421"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on ClipPathTextRecord {
          id
          title
          data {
            id
            title
            descriptionHtml
            speed
          }
        }
        ... on CursorMaskRecord {
          id
          text
          overlapText
          isDark
        }
        ... on ProjectMainBlockRecord {
          id
          pretitle
          title
          description
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          projectLogo {
            id
            alt
            url
            width
            height
          }
          masonryRows {
            id
            singleImage {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            twinImages {
              id
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
          tripleFlanked {
            id
            custom: responsiveImage(
              imgixParams: { fm: webp, q: "60", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
          mediaCarousel {
            id
            image {
              custom: responsiveImage(
                imgixParams: { fm: webp, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
            videoUrl
          }
          squareInfoList {
            id
            title
            description
            backgroundColor {
              hex
            }
            textColor {
              hex
            }
            borderColor {
              hex
            }
          }
        }
        ... on TextBlockRecord {
          id
          title
          description
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
        }
        ... on FullscreenVideoRecord {
          id
          overlayFullVideo
          previewVideo
          previewVideoMobile
          showMuteUnmute
        }
        ... on FullscreenImageRecord {
          id
          image {
            landscape: responsiveImage(
              imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            portrait: responsiveImage(
              imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
          imageMobile {
            id
            alt
            custom: responsiveImage(
              imgixParams: { fm: webp, q: "60", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
        }
        ... on VerticalCarouselRecord {
          id
          slides {
            id
            firstImage {
              landscape: responsiveImage(
                imgixParams: { fit: crop, w: 470, h: 470, ar: "16/9", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: { fit: crop, ar: "1/1", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
            secondImage {
              landscape: responsiveImage(
                imgixParams: { fit: crop, w: 470, h: 470, ar: "16/9", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: { fit: crop, ar: "1/1", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
            firstImageVideo
            secondImageVideo
          }
        }
        ... on FullscreenCarouselRecord {
          id
          slides {
            id
            pretitle
            title
            image {
              landscape: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1920
                  h: 1080
                  ar: "16/9"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
              portrait: responsiveImage(
                imgixParams: {
                  fit: crop
                  w: 1536
                  h: 2048
                  ar: "9/16"
                  fm: webp
                }
              ) {
                ...ResponsiveImageFragment
              }
            }
            linkUrl
            linkText
          }
        }
      }
    }
  }
`
