<template>
  <div class="footer-bottom row-90-16 text-caption">
    <div
      class="footer-corporate"
      v-html="$t('footer.bottom.corporate', { separator: '|' })"
    />

    <div class="footer-policies">
      <nuxt-link :to="localePath({ name: 'privacy-policy' })">
        {{ $t('footer.bottom.privacy') }}
      </nuxt-link>

      <nuxt-link :to="localePath({ name: 'cookie-policy' })">
        {{ $t('footer.bottom.cookie') }}
      </nuxt-link>

      <!-- <nuxt-link :to="localePath({ name: 'whistleblowing' })">
        {{ $t('footer.bottom.whistleblowing') }}
      </nuxt-link>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBottom',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
