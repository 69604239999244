import gsap from 'gsap'
import SplitType from 'split-type'

const pageTransition = {
  name: 'page-transiton',
  mode: 'out-in',
  css: false,
  onBeforeEnter: (el) => {
    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )

    gsap.set(preloader, { autoAlpha: 1, transformOrigin: 'top' })
  },
  onEnter: async (el, done) => {
    const headerImage = document.querySelector(
      '[data-animate="header-scaled-image"]'
    )

    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )
    const preloaderReveal = el.querySelectorAll(
      '[data-animate="preloader-reveal"]'
    )

    gsap.set(headerImage, { scale: 1.2, transformOrigin: 'center' })

    const splittingTitle = new SplitType(preloaderReveal, {
      tagName: 'span',
      types: 'lines',
    })

    preloaderReveal.forEach((el) => {
      //console.log(el)
      gsap.set(el, {
        overflow: 'hidden',
      })
      gsap.set(el.querySelectorAll('.line'), {
        // overflow: 'hidden',
        y: '110%',
      })
      /* gsap.set(el.querySelectorAll('.word'), {
        y: '110%',
        skewY: 7,
      }) */
    })

    const tl = gsap.timeline({
      paused: true,
      onComplete: async () => {
        done()
        await nextTick()
        tl.kill()
      },
    })
    tl.add('showContent')
    tl.to(preloader, {
      autoAlpha: 0,
      duration: 0.6,
      ease: 'power2.inOut',
    })

    if (headerImage) {
      tl.to(
        headerImage,
        {
          scale: 1,
          ease: 'power2.out',
          duration: 1.4,
        },
        'showContent'
      )
    }
    if (splittingTitle.lines?.length) {
      tl.to(splittingTitle.lines, {
        y: '0%',
        ease: 'power4.out',
        stagger: 0.15,
        duration: 0.8,
      })
    }

    await tl.play()
  },
  onLeave: async (el, done) => {
    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )
    const duration = 0.6
    const ease = 'power2.inOut'

    gsap.set(preloader, { autoAlpha: 0, transformOrigin: 'bottom' })

    const tl = gsap.timeline({
      paused: true,
      onComplete: async () => {
        done()
        tl.kill()
      },
    })
    tl.to(preloader, {
      autoAlpha: 1,
      duration,
      ease,
    })

    await tl.play()
    window.lenis.scrollTo(0, { immediate: true })
  },
}

export default pageTransition
