<template>
  <div>
    <NuxtLayout name="error">
      <div>
        <NavigationMenu :external="true" />

        <div
          class="page page-error p-top-200-120 p-bot-200-120 text-center row-90-16"
        >
          <h1
            class="title p-bot-80-50"
            v-html="
              error.statusCode === 500
                ? $t('errors.500.title')
                : $t('errors.404.title')
            "
          />

          <div class="page-error__content">
            <template v-if="error.statusCode === 500">
              <div class="text" v-html="$t('errors.500.text')" />
            </template>
            <template v-else>
              <p class="text" v-html="$t('errors.404.text')" />
            </template>

            <p class="text" v-html="$t('errors.suggestions.title')" />

            <ul class="m-top-32px">
              <li class="m-top-24px m-bottom-24px">
                <NuxtLink
                  :to="localePath({ name: 'index' })"
                  external
                  class="link-underline-1 text-caption accent"
                  ><strong>{{
                    $t('errors.suggestions.backhomeCta')
                  }}</strong></NuxtLink
                >
                <p
                  class="m-top-8px"
                  v-html="$t('errors.suggestions.backhomeText')"
                />
              </li>
              <li class="m-top-24px">
                <NuxtLink
                  :to="localePath({ name: 'get-in-touch' })"
                  external
                  class="link-underline-1 text-caption accent"
                  ><strong>{{
                    $t('errors.suggestions.contactsCta')
                  }}</strong></NuxtLink
                >
                <p
                  class="m-top-8px"
                  v-html="$t('errors.suggestions.contactsText')"
                />
              </li>
            </ul>
          </div>
        </div>

        <FooterMain :external="true" />
      </div>
      <div class="preloader-curtain" data-animate="preloader-curtain"></div>
    </NuxtLayout>
  </div>
</template>

<script setup>
import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  pageTransition,
})

const props = defineProps({
  error: Object,
})
console.error(props.error)

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
