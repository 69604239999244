import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'

export const getCookiePage = gql`
  ${seoFragment}
  query getCookiePage($locale: SiteLocale) {
    cookiePolicy(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      pretitle
      title
      bodyPretitle
      bodyTitle
      body
    }
  }
`

export const getPrivacyPage = gql`
  ${seoFragment}
  query getPrivacyPage($locale: SiteLocale) {
    privacyPolicy(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      pretitle
      title
      bodyPretitle
      bodyTitle
      body
    }
  }
`

export const getWhistlePage = gql`
  ${seoFragment}
  query getWhistlePage($locale: SiteLocale) {
    whistleblowing(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      pretitle
      title
      bodyPretitle
      bodyTitle
      body
    }
  }
`
