<template>
  <div
    class="nav-large__wrap flex is-justify-space-between"
    :class="{ '--light-theme': isLight }"
  >
    <!-- Logo -->
    <NuxtLink :to="localePath({ name: 'index' })" class="nav-large__logo">
      <img
        class="w-100 nav-large__logo--dark"
        src="@/assets/img/logo/logo-suntimes.svg"
        width="312"
        height="39"
        :alt="runtimeConfig.public.siteName"
      />
      <img
        class="w-100 nav-large__logo--light"
        src="@/assets/img/logo/logo-suntimes-white.svg"
        width="312"
        height="39"
        :alt="runtimeConfig.public.siteName"
        aria-hidden="true"
      />
    </NuxtLink>

    <div class="nav-large__list__wrap">
      <ul ref="nav" class="nav-large__list">
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'who' })"
            class="text-caption grow-ball"
            :class="{ '--active': route.name.includes('who') }"
          >
            <span>{{ $t('menu.menu-nav.who') }}</span>
            <span class="bolded">{{ $t('menu.menu-nav.who') }}</span>
          </nuxt-link>
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'what' })"
            class="text-caption grow-ball"
            :class="{ '--active': route.name.includes('what') }"
          >
            <span>{{ $t('menu.menu-nav.what') }}</span>
            <span class="bolded">{{ $t('menu.menu-nav.what') }}</span>
          </nuxt-link>
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'why' })"
            class="text-caption grow-ball"
            :external="external"
          >
            <span>{{ $t('menu.menu-nav.why') }}</span>
            <span class="bolded">{{ $t('menu.menu-nav.why') }}</span>
          </nuxt-link>
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'get-in-touch' })"
            class="text-caption grow-ball"
            :external="external"
          >
            <span>{{ $t('menu.menu-nav.getInTouch') }}</span>
            <span class="bolded">{{ $t('menu.menu-nav.getInTouch') }}</span>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLarge',
}
</script>

<script setup>
const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const lenis = inject('lenis')

const nav = ref(null)
const menuButton = ref(null)
const logo = ref(null)
const logoDot = ref(null)
const duration = 0.4

const isHover = ref(false)
const isNewPage = ref(true)

const props = defineProps({
  isLight: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => isHover.value,
  (isHover) => {
    if (isHover) {
      // animateMenuIn().play()
    } else {
      if (lenis.value.scroll > 100 && !isNewPage.value) {
        // animateMenuOut().play()
      }
    }
  }
)

watch(
  () => route.path,
  () => {
    isNewPage.value = true
  }
)
</script>

<style lang="scss">
@import './style.scss';
</style>
