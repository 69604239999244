import revive_payload_client_aiEoYav5bG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZiahGeKNz7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1QFX1jcLOD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CDQpzyf0LH from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@20.14.14_sass@1.7_ok7bs356g5spw7qlnfu3cu7byu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_pjQXp4ZnSK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5aY5sLPP1s from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YaCOs6QZA6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Blcq3GvmxA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_J0g0aG2UqC from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.20.0_typescript@5.4.4_vue@3.4.35_typescript@5.4.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_rkQWc1jVAd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Xq8VdOaECo from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_3v2qs6tyz53yrmavcl6cnmkfh4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_Z4Ck2xwkwo from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import templates_pwa_client_4ca03280_kPlDuWKOTZ from "/opt/build/repo/.nuxt/templates.pwa.client.4ca03280.ts";
import plugin_Evn6i0hg71 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.4_rollup@4.20.0_typescript@5.4.4_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_qj2YYTfkF7 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_vUCJiMpkKZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.4.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
export default [
  revive_payload_client_aiEoYav5bG,
  unhead_ZiahGeKNz7,
  router_1QFX1jcLOD,
  _0_siteConfig_CDQpzyf0LH,
  payload_client_pjQXp4ZnSK,
  navigation_repaint_client_5aY5sLPP1s,
  check_outdated_build_client_YaCOs6QZA6,
  chunk_reload_client_Blcq3GvmxA,
  plugin_vue3_J0g0aG2UqC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rkQWc1jVAd,
  plugin_Xq8VdOaECo,
  nuxt_plugin_Z4Ck2xwkwo,
  templates_pwa_client_4ca03280_kPlDuWKOTZ,
  plugin_Evn6i0hg71,
  switch_locale_path_ssr_qj2YYTfkF7,
  i18n_vUCJiMpkKZ,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q
]