export const debounce = (callback, wait = 250) => {
  let timeout
  return (...args) => {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => callback.apply(context, args), wait)
  }
}

export const lerp = (start, end, amount) => {
  return start + (end - start) * amount
}
