import { parallaxElement } from '~/plugins/animation-directives/parallax-element.js'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default defineNuxtPlugin((nuxtApp) => {
  // Parallax Image
  nuxtApp.vueApp.directive('anim-parallax-element', {
    mounted(el, binding) {
      parallaxElement(el, binding)
      //TODO: Non è bellissima come soluzione ma serve per fare un refresh dopo i caricamenti dei vari elementi della pagina
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 100)
    },
  })
})
