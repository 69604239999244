import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images.js'

export const getTouchPage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query getTouchPage($locale: SiteLocale) {
    getInTouch(locale: $locale) {
      id
      seo {
        ...SeoFragment
      }
      pretitle
      wordsPrefix
      animatedWords {
        id
        text
      }
      headquartersCarousel {
        id
        slides {
          id
          pretitle
          title
          image {
            landscape: responsiveImage(
              imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
            portrait: responsiveImage(
              imgixParams: { fit: crop, w: 1536, h: 2048, ar: "9/16", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
          linkUrl
          linkText
        }
      }
      contactsList {
        id
        visibleTitle
        hiddenTitle
        visibleDescription
        hiddenDescription
        linkUrl
        linkLabel
      }
    }
  }
`
