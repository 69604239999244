import { default as indexO19aScTM0SMeta } from "/opt/build/repo/pages/be-suntimers/index.vue?macro=true";
import { default as indexQ7EC96mn4oMeta } from "/opt/build/repo/pages/cookie-policy/index.vue?macro=true";
import { default as indexrAuuacHkk9Meta } from "/opt/build/repo/pages/fight-gravity-event/index.vue?macro=true";
import { default as indexuJXuDt3rtiMeta } from "/opt/build/repo/pages/fight-gravity/index.vue?macro=true";
import { default as indexpzMRyvQTTbMeta } from "/opt/build/repo/pages/get-in-touch/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as index9d90rQCWYDMeta } from "/opt/build/repo/pages/privacy-policy/index.vue?macro=true";
import { default as indexBmiOq17LApMeta } from "/opt/build/repo/pages/projects/[slug]/index.vue?macro=true";
import { default as index6xlv7fvD7GMeta } from "/opt/build/repo/pages/what/index.vue?macro=true";
import { default as indexFtj1cO7jywMeta } from "/opt/build/repo/pages/who/index.vue?macro=true";
import { default as indexhtcyXUnZLsMeta } from "/opt/build/repo/pages/why/index.vue?macro=true";
import { default as component_45stub1sI9F7xiD2Meta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1sI9F7xiD2 } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.14_encoding@0.1.13_eslint@8.57.0_ioredis@_iphyrd5ifpzaawzrpldrsnyz2i/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "be-suntimers___en",
    path: "/be-suntimers",
    meta: indexO19aScTM0SMeta || {},
    component: () => import("/opt/build/repo/pages/be-suntimers/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en",
    path: "/cookie-policy",
    meta: indexQ7EC96mn4oMeta || {},
    component: () => import("/opt/build/repo/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "fight-gravity-event___en",
    path: "/fight-gravity-event",
    meta: indexrAuuacHkk9Meta || {},
    component: () => import("/opt/build/repo/pages/fight-gravity-event/index.vue").then(m => m.default || m)
  },
  {
    name: "fight-gravity___en",
    path: "/fight-gravity",
    meta: indexuJXuDt3rtiMeta || {},
    component: () => import("/opt/build/repo/pages/fight-gravity/index.vue").then(m => m.default || m)
  },
  {
    name: "get-in-touch___en",
    path: "/get-in-touch",
    meta: indexpzMRyvQTTbMeta || {},
    component: () => import("/opt/build/repo/pages/get-in-touch/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    meta: index9d90rQCWYDMeta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___en",
    path: "/projects/:slug()",
    meta: indexBmiOq17LApMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "what___en",
    path: "/what",
    meta: index6xlv7fvD7GMeta || {},
    component: () => import("/opt/build/repo/pages/what/index.vue").then(m => m.default || m)
  },
  {
    name: "who___en",
    path: "/who",
    meta: indexFtj1cO7jywMeta || {},
    component: () => import("/opt/build/repo/pages/who/index.vue").then(m => m.default || m)
  },
  {
    name: "why___en",
    path: "/why",
    meta: indexhtcyXUnZLsMeta || {},
    component: () => import("/opt/build/repo/pages/why/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub1sI9F7xiD2Meta?.name,
    path: "/sitemap.xml",
    component: component_45stub1sI9F7xiD2
  }
]