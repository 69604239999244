import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import queries from '@/graphql/dato/queries/index.js'

export const useStore = defineStore('store', () => {
  const { locale } = useI18n()

  const seoFallback = ref({})
  const state = ref(null)
  const lottieDone = ref(false)

  const setLottie = (value) => {
    lottieDone.value = value
  }

  // Restituisco la configurazione completa utile per dati commons
  const datoConfiguration = computed(() => ({
    seoFallback: seoFallback.value,
  }))

  // Estraggo la configurazione base di Dato
  const fetchDatoConfiguration = async () => {
    try {
      const { data } = await useAsyncQuery(queries.dato.datoState, {
        locale: locale.value,
      })
      seoFallback.value = data.value?._site?.globalSeo
    } catch (e) {
      console.error(e)
    }
  }

  return {
    state,
    lottieDone,
    setLottie,
    datoConfiguration,
    fetchDatoConfiguration,
  }
})
