export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const cookie = useCookie(`apollo:default.token`)

  cookie.value = runtimeConfig.public.graphqlApiKey

  nuxtApp.hook('apollo:auth', ({ token }) => {
    token.value = cookie.value
  })

  nuxtApp.hook('apollo:error', (error) => {
    console.error('Apollo error:', error)

    if (error.networkError) {
      console.error('Network error:', error.networkError)
      // Handle network errors (e.g., API down, timeout)
      console.error('A network error occurred. Please try again later.')
    }

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      error.graphQLErrors.forEach((graphQLError) => {
        console.error('GraphQL error:', graphQLError)
        // Handle specific GraphQL error cases based on error codes or messages
        switch (graphQLError.extensions?.code) {
          case 'UNAUTHENTICATED':
            console.error('You are not authenticated. Please login again.')
            // Maybe redirect to login page or refresh token
            break
          case 'FORBIDDEN':
            console.error('You do not have permission to perform this action.')
            break
          case 'INTERNAL_SERVER_ERROR':
            console.error('A server error occurred. Please try again later.')
            break
          default:
            console.error(`Error: ${graphQLError.message}`)
        }
      })
    }
  })
})
