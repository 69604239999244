<template>
  <div
    class="nav-small__wrap flex is-justify-space-between"
    :class="{ '--light-theme': isLight, '--overlay-open': overlayOpen }"
  >
    <!-- Logo -->
    <NuxtLink
      :to="localePath({ name: 'index' })"
      :external="external"
      class="nav-small__logo"
    >
      <img
        class="w-100 nav-small__logo--dark"
        src="@/assets/img/logo/logo-suntimes.svg"
        width="205"
        height="25"
        :alt="runtimeConfig.public.siteName"
      />
      <img
        class="w-100 nav-small__logo--light"
        src="@/assets/img/logo/logo-suntimes-white.svg"
        width="205"
        height="25"
        :alt="runtimeConfig.public.siteName"
      />
    </NuxtLink>

    <div class="nav-small__list__wrap">
      <NavigationHamburger
        :is-Active="overlayOpen"
        @click="openOverlay"
        :isLight="isLight"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuSmall',
}
</script>

<script setup>
const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  isLight: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
  overlayOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['open-menu'])

// Gestisco Overlay
const openOverlay = () => {
  emit('open-menu')
}
</script>

<style lang="scss">
@import './style.scss';
</style>
