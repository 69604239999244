import * as home from './home.js'
import * as projects from './projects.js'
import * as getintouch from './getintouch.js'
import * as corporates from './corporates.js'
import * as suntimers from './be-suntimers.js'
import * as what from './what.js'
import * as why from './why.js'
import * as who from './who.js'
import * as event from './event.js'
import * as sitemap from './sitemap.js'
import * as dato from './dato.js'

export default {
  home,
  projects,
  getintouch,
  corporates,
  suntimers,
  what,
  why,
  who,
  event,
  sitemap,
  dato,
}
