export const getSitemapData = `
  query GetSitemapData(
    $locale: SiteLocale 
    $first: IntType
  ) {
    allProjects(locale: $locale, first: $first) {
      id
      slug
      _allSlugLocales {
        value
        locale
      }
    }
  }
`
