export const setSizes = (el, binding) => {
  document.documentElement.style.setProperty(
    `--${binding.value.id}-width`,
    el.getBoundingClientRect().width + 'px'
  )
  document.documentElement.style.setProperty(
    `--${binding.value.id}-height`,
    el.getBoundingClientRect().height + 'px'
  )
}

export const setViewportSizes = () => {
  document.body.style.setProperty('--viewport-width', window.innerWidth + 'px')
  document.body.style.setProperty(
    '--viewport-height',
    window.innerHeight + 'px'
  )
}
