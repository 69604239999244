<template>
  <div class="footer-newsletter p-top-32px" :class="{ '--is-hover': isHover }">
    <div class="footer-newsletter-wrap row-90-16">
      <h3
        class="text-l text-center m-bottom-8px"
        v-anim-reveal-text
        data-animate="reveal-text"
      >
        {{ $t('footer.newsletter.title') }}
      </h3>
      <p class="text text-center" v-html="$t('footer.newsletter.subtitle')" />
      <div
        class="footer-newsletter-form m-top-32px"
        :class="{ '--form-submitted': formSubmitted }"
      >
        <FooterNewsletterForm
          v-if="!formSubmitted"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          @newsletter:success="handleFormSubmit(true)"
          @newsletter:failure="handleFormSubmit(false)"
        />

        <div
          v-if="formSubmitted"
          class="footer-newsletter-form-thanks text-center"
        >
          <p class="text-m m-bottom-24px">
            {{ $t('footer.newsletter.successTitle') }}
          </p>
          <button class="button-round-1" @click="formSubmitted = false">
            <span>{{ $t('footer.newsletter.resetCta') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterNewsletter',
}
</script>

<script setup>
const isHover = ref(false)
const formSubmitted = ref(false)

const handleFormSubmit = (submitSuccess) => {
  formSubmitted.value = submitSuccess
  // Qua eventi lead ecc per gtm..
}
</script>

<style lang="scss">
@import './style.scss';
</style>
