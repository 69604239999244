import SplitType from 'split-type'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const revealText = (
  el,
  triggerType = 'scroll',
  startPosition = 'top center'
) => {
  const duration = 0.8
  gsap.set(el, { opacity: 0 })

  // Wrappa le parole nei tag annidati prima di eseguire SplitType
  wrapWordsInTag(el)

  let elementsToSplit = el.querySelectorAll('*')

  if (elementsToSplit.length === 0) {
    elementsToSplit = [el]
  }

  elementsToSplit.forEach((element) => {
    new SplitType(element, { types: 'words, lines' })
  })

  applyMarginLeftZero(el)
  // console.log(el)

  gsap.set(el.querySelectorAll('.line'), {
    overflow: 'hidden',
  })
  gsap.set(el.querySelectorAll('.word'), {
    y: '110%',
  })

  const animateText = () => {
    gsap.set(el, { opacity: 1 })

    const tl = gsap.timeline({
      onComplete: () => {
        el.querySelectorAll('.line').forEach((line, index) => {
          line.style.overflow = 'visible'
        })
        tl.kill()
      },
    })

    el.querySelectorAll('.line').forEach((line, index) => {
      tl.to(
        line.querySelectorAll('.word'),
        {
          y: '0%',
          ease: 'power2.out',
          duration,
        },
        index * 0.05
      )
    })
  }

  if (triggerType === 'scroll') {
    ScrollTrigger.create({
      trigger: el,
      start: startPosition,
      onEnter: animateText,
      once: true, // Assicura che l'animazione avvenga una sola volta
    })
  } else if (triggerType === 'play') {
    animateText()
  }
}

// Funzione per wrappare ogni parola dentro il suo tag mantenendo le classi
const wrapWordsInTag = (el) => {
  const elements = el.querySelectorAll('strong, span')

  elements.forEach((element) => {
    const tagName = element.tagName.toLowerCase()
    const classList = element.className ? ` class="${element.className}"` : ''

    // Cattura il contenuto testuale dell'elemento
    const content = element.textContent.trim() // Usando textContent per evitare HTML annidato

    // Divide il contenuto in parole
    const words = content.split(/\s+/)

    // Wrappa ogni parola e rigenera l'HTML dell'elemento
    const wrappedWords = words
      .map((word) => `<${tagName}${classList}>${word}</${tagName}>`)
      .join('')

    // Inserisce l'HTML wrappato prima dell'elemento corrente
    element.insertAdjacentHTML('beforebegin', wrappedWords)

    // Rimuove l'elemento originale
    element.remove()
  })
}

const applyMarginLeftZero = (el) => {
  // Seleziona tutti gli elementi con classe "word"
  const wordElements = el.querySelectorAll('.word')

  wordElements.forEach((element) => {
    const content = element.textContent.trim() // Ottieni il contenuto dell'elemento

    // Se il contenuto è solo un punto, punto e virgola o due punti
    if (/^[\.;:]+$/.test(content)) {
      // Applica lo stile margin-left: 0
      element.style.marginLeft = '0'
    }
  })

  // Rimuove spazi o &nbsp; tra strong + strong o b + b
  const strongElements = el.querySelectorAll('strong + strong, b + b')

  strongElements.forEach((element) => {
    // Verifica se l'elemento precedente contiene solo spazi o &nbsp;
    const previousElement = element.previousSibling

    if (
      previousElement &&
      previousElement.nodeType === Node.TEXT_NODE &&
      /^\s*$/.test(previousElement.textContent)
    ) {
      previousElement.remove() // Rimuove lo spazio vuoto tra i strong
    }
  })
}
